// Import site utilities.
@import '../../global/utils/init';

#edit-submitted-service-areas {

  .form-item {
    position: relative;

    .option {
      padding-left: 30px;

      &::before {
        background-color: var(--color-white);
        border-radius: 4px;
        content: '';
        display: inline-block;
        height: 20px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
      }
    }
  }

  .form-checkbox {
    cursor: pointer;
    position: absolute;
    opacity: 0;

    &:checked {

      + .option {

        &::before {
          background-color: var(--color-sushi);
        }

        &::after {
          border: solid var(--color-white);
          border-width: 0 2px 2px 0;
          content: '';
          display: block;
          height: 10px;
          left: 6px;
          position: absolute;
          top: 45%;
          transform: translateY(-50%) rotate(45deg);
          width: 6px;
        }
      }
    }
  }
}
