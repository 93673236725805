// Import site utilities.
@import '../../global/utils/init';

.front {

  .l-wrapper-inner {

    .history-of-growth {
      background-color: var(--color-pharlap) !important;
      min-height: unset !important;
      padding: 0;
      position: relative;
    
      .block-content {
        display: flex;
        gap: 30px;

        .block-content-wrapper {
          padding: 50px 0;
        }
  
        p {
          margin-bottom: 20px !important;
        }

        .text-align-right {
          margin-bottom: 0 !important;
          margin-right: calc(-50vw + 50%);

          img {
            height: 100%;
            margin: 0;
            min-width: 300px;
            object-fit: cover;
          }
        }
      }
    }

    .block-hero {
      padding: 0;

      .hero-inner {
        // background: rgba(93, 64, 55, 0.8);
        max-width: 50%;
        min-height: 350px;

        .block--inner-wrapper {
          max-width: 600px;
          padding: 40px 20px;
          margin-left: 300px;
        }

        .block-title {
          font-size: 32px;
          text-shadow: none;
          margin-bottom: 30px;
          text-align: left;
        }

        .block-content {
          color: var(--color-white);
          font-size: 18px;
          text-align: left;
        }
      }
    }

    .l-content {

      .intro-kata-block {
        padding: 0;
        min-height: unset;
      }

      .kata-hero-image-block {
        padding-bottom: 30px;
      }
    }
  }
}

// global contact form design

.not-front {

  .contact-form {
    padding: 60px 0;
  }
}

.contact-form {
  background-color: var(--color-pharlap) !important;
  margin-bottom: -30px !important;

  .block-title {
    color: var(--color-white);
    font-size: 32px;
    text-align: center;
  }

  .webform-client-form {
    margin: 0 auto;

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      > .form-item {
        margin: 0;
        width: calc(50% - 15px);
      }

      .webform-component--service-areas {
        flex: 0 0 100%;
        padding: 0 25px;
      }

      #edit-submitted-service-areas {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .webform-component--who-would-you-like-to-talk-to {
        flex: 0 0 100%;

        .form-select {
          border-radius: 8px;
          width: 100%;
        }
      }

      .webform-component--message {
        flex: 0 0 100%;

        .form-textarea {
          border-radius: 8px;
          padding: 0.4em;
        }
      }

      .form-actions {
        flex: 0 0 100%;
        margin: 20px 0 0;
        text-align: center;
      }
    }

    input {
      border-radius: 8px;
      padding: 0.4em;
    }

    input[type='checkbox'] {
      transform: scale(1.7);
    }

    input[type='submit'] {
      background-color: var(--color-millbrook);
      padding: 10px 30px;
    }
  }
}


.block-node-content {

  &.node-type-slideshow {
    padding: 0 !important;

    .container {
      max-width: unset;
      padding: 0;
    }
  }
}

@include lg {

  .contact-form {

    .block-title {
      font-size: 30px;
      line-height: 34px;
    }
  }
}

@include md {

  .front {

    .l-wrapper-inner {

      .l-content {

        .block {

          .block-title {
            font-size: 30px;
            line-height: 34px;
          }
        }

        .intro-kata-block {
          padding: 0;
          min-height: unset;
        }
  
        .kata-hero-image-block {
          padding: 10px 0 30px;
          min-height: unset;
        }
      }
    }
  }

  .contact-form {

    .webform-client-form {

      > div {
        flex-direction: column;

        .form-item {
          width: 100%;
          
          &.webform-component--service-areas {
            padding: 0;
          }
        }

        #edit-submitted-service-areas {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      input[type='checkbox'] {
        transform: scale(1.3);
      }
    }
  }

  .not-front {

    .contact-form {
      padding: 30px 0;
    }
  }
}
