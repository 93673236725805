// Import site utilities.
@import '../../global/utils/init';

.flexslider {

  .slides {
    display: flex;

    li {

      > .paragraphs-item {
        height: 100%;

        > .content {
          height: 100%;
        }
      }
    }
  }
}

.slider_item {
  display: flex;
  height: 100%;
  position: relative;

  .slider_left {
    position: relative;
    width: 50%;

    .slider_bg {

      .color-swatch {
        bottom: 0;
        height: 100% !important;
        position: absolute;
        top: 0;
        width: 100% !important;
        z-index: 0;
      }
    }

    .slider_body {
      color: var(--color-white);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin-left: auto;
      max-width: 615px;
      overflow: hidden;
      position: relative;
      padding: 30px 80px 60px 15px;

      h2 {
        font-size: 44px;
        line-height: 44px;
        margin-bottom: 15px;

        &:empty {
          display: none;
        }
      }

      h3 {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 15px;

        &:empty {
          display: none;
        }
      }

      p {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .slider_image {
    width: 50%;

    * {
      height: 100%;
    }

    .field-item {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    img {
      max-height: 500px;
      margin: 0;
      width: auto;
      object-fit: contain;
    }
  }
}

.field-name-field-slideshow {

  .flexslider {
    border: 0;
    margin: 0;
    position: relative;

    .flex-control-nav {
      bottom: 13px;
      position: absolute;
      z-index: 11;
    }

    .flex-direction-nav {
      bottom: 30px;
      margin: 0 auto;
      max-width: 320px;
      position: relative;
      z-index: 11;

      li {
        margin: 0;
      }

      a {
        display: block;
        opacity: 1;

        &::before {
          height: 40px;
          padding-top: 8px;
        }
      }

      .flex-prev {
        left: 0;
      }

      .flex-next {
        right: 0;
      }
    }
  }
}

.field-name-field-existing-content {

  .node-slideshow {
    margin-left: calc(-50vw + 50%);
    width: 100vw;
  }

  > .field-items {

    > .field-item {
      margin-bottom: 30px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

@include lg {
  
  .slider_item {

    .slider_left {

      .slider_body {

        h2 {
          font-size: 24px;
          line-height: 28px;
        }

        h3 {
          font-size: 20px;
          line-height: 24px;
        }

        p {
          line-height: 20px;
        }
      }
    }
  }

  .flex-direction-nav {

    a {

      &::before {
        font-size: 24px;
      }
    }
  }
}

@include md {

  .slider_item {
    flex-direction: column;

    .slider_left {
      width: 100%;

      .slider_body {
        padding: 20px;
      }
    }

    .slider_image {
      width: 100%;
    }
  }

  .flex-direction-nav {

    a {

      &::before {
        font-size: 20px;
      }
    }
  }
}
