// Import site utilities.
@import '../../global/utils/init';

.layout {
  height: auto;
  margin: 0 !important;
  padding: 0 !important;
}

// Spacing
.l-wrapper {
  min-height: unset;
  overflow: hidden;
  padding: 0;

  .l-top,
  .l-content,
  .l-bottom {
    margin-bottom: 30px;

    .block {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.front {

  .l-wrapper {
  
    .l-top,
    .l-wrapper-inner {
      margin-bottom: 0;
  
      .block {
        margin-bottom: 0;
      }
    }
  }
}

.not-front {

  .l-header {
    margin-bottom: 0;
  }

  .l-wrapper {
    margin-top: 30px;

    .l-content {

      > * {
        margin-bottom: 30px;

        &:has(.field-name-field-existing-content) {
          margin-bottom: -30px;
        }
      }
    }
  }
}
