// Import site utilities.
@import '../../global/utils/init';

.flip-card {
  aspect-ratio: 1 / 1;
  background-color: var(--color-millbrook);
  border-radius: 50%;
  overflow: hidden;
  perspective: 1000px;
  width: 100%;

  .flip-card-inner {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    position: relative;
    text-align: center;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    width: 100%;

    .flip-card-front,
    .flip-card-back {
      backface-visibility: hidden;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .flip-card-front {
      background-color: #bbb;
      color: var(--color-black);

      h3 {
        align-items: center;
        background: rgba(93, 64, 55, 0.8);
        bottom: 0;
        color: var(--color-white);
        display: flex;
        font-size: 34px;
        justify-content: center;
        left: 0;
        margin: 0;
        overflow: hidden;
        padding: 30px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .flip-card-back {
      background-color: var(--color-millbrook);
      color: var(--color-white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px 35px;
      transform: rotateY(180deg);

      p {
        margin-bottom: 15px;
      }

      .read-more-link {
        color: var(--color-sushi) !important;
        margin-top: 10px;
      }
    }
  }

  &:hover {

    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.block-views-kata-consulting-service-offerings-block {
  background-color: var(--color-white) !important;
  padding: 70px 0 100px !important;

  .view-header {
    margin-bottom: 50px;
    padding: 0;

    h3 {
      color: var(--color-black);
      font-size: 40px;
      line-height: 40px;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .view-content {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 310px);
    justify-content: space-between;
  }
}

.node-type-services {

  .node-services {

    .field-name-field-image {
      float: right;
      margin-left: 25px;
      margin-bottom: 20px;
    }

    .field-name-body {
      border-left: 60px solid var(--color-millbrook);
      padding-left: 20px;
    }
  }
}

@include lg {

  .flip-card {

    .flip-card-inner {

      .flip-card-front {

        h3 {
          font-size: 26px;
          line-height: 28px;
        }
      }
    }
  }
  
  .block-views-kata-consulting-service-offerings-block {
    padding: 30px 0 !important;

    .view-header {

      h3 {
        font-size: 30px;
        line-height: 34px;
      }
    }

    .view-content {
      grid-template-columns: repeat(2, 310px);
      justify-content: center;
    }
  }

  .node-type-services {

    .node-services {

      .field-name-body {
        border-left: 20px solid var(--color-millbrook);
      }

      .field-name-field-image {
        width: 50%;
      }
    }
  }
}

@include md {

  .block-views-kata-consulting-service-offerings-block {

    .view-header {
      margin-bottom: 25px;
    }

    .view-content {
      grid-template-columns: repeat(1, 310px);
      justify-content: center;
    }
  }

  .node-type-services {

    .node-services {

      .field-name-field-image {
        float: none;
        margin-left: 0;
        width: auto;
      }
    }
  }
}
