// Import site utilities.
@import '../../global/utils/init';

.block-system-main-menu {

  .menu {

    > li {
      filter: none !important;
      padding: 12px 0;

      a {
        background: transparent;
        color: var(--color-white);
        filter: none;
        font-family: var(--font-avenir);
        font-size: 16px;
        font-weight: 900;
        line-height: 20px;
        padding: 0 12px;
        position: relative;
        transition: 0.5s;
        white-space: inherit;

        .sub-arrow {
          border-color: var(--color-white) transparent transparent transparent;
        }

        &:hover,
        &:focus,
        &.active,
        &.active-trail {
          background: transparent !important;
          color: var(--color-sushi);
          font-weight: 900;
          line-height: 20px;
          padding: 0 12px;

          .sub-arrow {
            border-color: var(--color-sushi) transparent transparent transparent;
          }
        }
      }

      > a {
        border-right: 1px solid var(--color-gray);
      }

      ul {
        background: transparent;

        li {
          background: var(--color-millbrook);
          padding: 10px 0;

          &:first-child {
            padding-top: 20px;
          }

          &:last-child {
            padding-bottom: 20px;
          }
        }
      }

      &:hover,
      &:focus {
        background: transparent;

        > a {
          color: var(--color-sushi);

          .sub-arrow {
            border-color: var(--color-sushi) transparent transparent transparent;
          }
        }
      }

      &.has-children {

        > a {
          padding-right: 25px;
        }
      }

      &:last-child {

        > a {
          border: 0;
        }
      }
    }
  }
}

@include md {

  .block-system-main-menu {

    .menu {

      > li {
        float: none;
        border-bottom: 1px solid #bbbbbb;

        &.last {
          margin-bottom: 10px;
        }

        > a {
          border-right: 0;
          display: block;
          padding: 0 5px !important;

          .active {
            padding: 0 5px !important;
          }
        }
      }
    }
  }
}
