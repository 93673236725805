// Import site utilities.
@import '../../global/utils/init';


.header-identity-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  float: unset;
}

.l-header {
  background: var(--color-millbrook);
  box-shadow: 0 3px 6px var(--color-box-shadow);
  left: 0;
  max-width: 100vw;
  padding: 20px 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  transition: 0.5s;

  .l-header-inner {
    align-items: center;
    color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1226px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;

    .container {
      padding: 0;
    }

    .header-site-name-wrapper {
      min-height: unset;
      padding-bottom: 20px;
    }

    &::after,
    &::before {
      display: none;
    }

    .header-logo-wrapper {
      height: auto;
      margin: 0;
      max-height: unset;
      max-width: unset;

      .header-logo {
        height: auto;
        margin: 0;
        max-height: unset;
        max-width: unset;
        transition: 0.5s;
        width: 252px;
      }
    }
  }

  .block-system-header {
    margin-top: auto;
    min-height: unset;
    transition: 0.5s;

    .block--inner-wrapper {
      padding: 0;
    }

    .block-content {
      display: block;
    }
  }

  .block-menu-menu-header-menu {
    position: absolute;
    right: 15px;
    top: 0;
    width: 250px;

    .menu {

      li {
        display: block;
        margin-bottom: 10px;
        width: 100%;

        a {
          background: var(--color-pharlap);
          border-radius: 30px;
          color: var(--color-white);
          font-family: var(--font-avenir);
          font-size: 16px;
          font-weight: bold;
          padding: 5px 15px;
          text-align: center;
          transition: 0.5s;

          &:hover,
          &.active {
            background: var(--color-sushi);
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .block-system-main-menu {
    background: transparent;
  }
}

.scrolled {

  .l-header {
    padding-top: 15px;

    .l-header-inner {
      align-items: end;
      flex-direction: row;
      justify-content: space-between;

      .header-site-name-wrapper {
        padding-bottom: 15px;

        .header-logo-wrapper {

          .header-logo {
            width: 180px;
          }
        }
      }

      .block-menu-menu-header-menu {
        order: 3;
        padding-bottom: 15px;
        position: initial;
      }
    }
  }
}

.admin-bar {

  .l-header {
    top: 36px;
  }
}

@include lg {

  .l-header {

    .l-header-inner {

      .header-logo-wrapper {

        .header-logo {
          width: 180px;
        }
      }
    }
  }
}

@include md {

  .l-header {

    .l-header-inner {
      align-items: center !important;
      flex-direction: column !important;

      .block-menu-menu-header-menu {
        padding-bottom: 0 !important;
      }
    }
  }

  .admin-bar {

    .l-header {

      &.no-scroll {
        top: 36px;
      }
    }
  }

  .l-header {

    &.scroll {
      top: -260px;
    }

    &.no-scroll {
      top: 0;
    }

    .menu-toggle-button {
      width: 26px;
      margin-left: 0;
      height: 27px;

      .menu-toggle-button-text {
        display: none;
      }
    }
    
    .l-header-inner {

      .header-site-name-wrapper {
        padding-bottom: 15px;
      }

      .header-logo-wrapper {

        .header-logo {
          width: 180px;
        }
      }

      .block-system-header {
        order: 1;
      }

      .block-system-main-menu {
        align-self: flex-start;
        order: 3;
        padding: 10px 0 !important;
        width: 100%;
      }

      .block-menu-menu-header-menu {
        order: 2;
        position: unset;
        max-width: 190px;

        .menu {

          li {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              font-size: 14px;
              padding: 5px 14px;
            }
          }
        }
      }
    }
  }
}
