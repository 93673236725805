// Import site utilities.
@import '../../global/utils/init';

.item-list {

  .pager {
    margin: 30px 0 0;
    gap: 15px;
  
    li {
      background-color: transparent;
      font-size: 17px;
      line-height: 24px;
      padding: 0;
  
      &.pager-current,
      &.pager-ellipsis {
        color: var(--color-sushi);
        font-weight: 200;
      }
  
      &.pager-current {
        text-decoration: underline;
      }
  
      &.pager-next {
        padding-right: 5px;
      }
  
      &.pager-next,
      &.pager-last,
      &.pager-first,
      &.pager-previous {
        
        a {
          text-decoration: underline;
        }
      }
  
      a {
        background-color: transparent;
        border: none;
        color: var(--color-sushi);
        margin: 0;
        padding: 0;
      }
    }
  }
}

.page .l-content div.item-list ul.pager a {
  color: var(--color-sushi);
}

@include md {

  .item-list {

    .pager {
      margin: 20px 0 0;
      height: 20px;
    
      li {
        font-size: 16px;
        line-height: 22px;
    
        &.pager-next,
        &.pager-last,
        &.pager-first,
        &.pager-previous {
          
          a {
            width: 13px;

            &::before,
            &::after {
              border-color: var(--color-sushi);
            }
          }
        }
      }
    }
  }
}
