// Import site utilities.
@import '../../global/utils/init';

.paragraphs-item-horizontal-flowchart  {

  .field-name-field-flowchart-item {

    .field-items {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .field-item {

        .paragraphs-item-flowchart-item {
          border-radius: 20px;
          color: var(--color-white);
          max-width: 75%;
          padding: 25px 40px 40px;
          position: relative;
        
          .field-name-field-title {
            font-size: 20px;
            line-height: 24px;
          }
        
          .field-name-field-body {
        
            ul,
            ol {
              margin: 0;
              padding-top: 0;
        
              li {
                margin: 0;
              }
            }
          }

          &.alignment-center {
            margin: 0 auto;
          }

          &.alignment-left {
            margin-right: auto;
          }

          &.alignment-right {
            margin-left: auto;
          }
        }

        &:first-child {

          .paragraphs-item-flowchart-item {

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.paragraphs-item-vertical-flowchart  {

  .field-name-field-flowchart-item {

    > .field-items {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: center;

      > .field-item {
        width: calc(20% - 12px);

        .paragraphs-item-flowchart-item {
          border-radius: 10px;
          color: var(--color-white);
          height: 100%;
          min-height: 250px;
          padding: 5px;
          position: relative;
          padding-left: 40px;
        
          &::before {
            background: url('../images/right-arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            bottom: 15px;
            content: '';
            height: 35px;
            display: block;
            position: absolute;
            right: -25px;
            width: 35px;
            z-index: 1;
          }
        
          .field-name-field-title {
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
            margin: 0;
            position: absolute;
            padding-left: 5px;
            padding-top: 10px;
            text-wrap: nowrap;
            transform: rotate(90deg);
            transform-origin: 0 0;
          }

          &.alignment-center {
            margin: 0 auto;
          }

          &.alignment-left {
            margin-right: auto;
          }

          &.alignment-right {
            margin-left: auto;
          }
        }

        &:nth-child(5n),
        &:last-child {

          .paragraphs-item-flowchart-item {

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include lg {

  .paragraphs-item-horizontal-flowchart {

    .field-name-field-flowchart-item {

      .field-items {

        .field-item {

          .paragraphs-item-flowchart-item {
            max-width: 100%;
            padding: 20px;

            .field-name-field-title {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .paragraphs-item-vertical-flowchart {

    .field-name-field-flowchart-item {

      > .field-items {
        flex-direction: column;

        > .field-item {
          width: 100%;

          .paragraphs-item-flowchart-item {

            &::before {
              bottom: -20px;
              right: 15px;
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }
}
