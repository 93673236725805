// Import site utilities.
@import '../../global/utils/init';

.view-resources {

  .view-content {

    .views-row {
      background: var(--color-pharlap);
      padding: 20px 15px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    
    ul {
      list-style-type: none;
    }
  }

  .resource-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .resource-left-content {
      color: var(--color-white);

      .title {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 10px;

        a {
          color: var(--color-dark-gray);
        }
      }
  
      .body {
        margin-bottom: 5px;
        line-height: 22px;
      }

      .views-more-link {
        color: var(--color-dark-gray);
      }

      .resource-type {
        margin-top: 20px;

        a {
          border-radius: 5px;
          background: var(--color-sushi);
          color: var(--color-white);
          display: inline-block;
          font-size: 16px;
          line-height: 18px;
          padding: 8px 15px;
          text-align: center;
        }
      }
    }

    .resource-right-content {

      img {
        max-width: 300px;
        margin-bottom: 0;
      }
    }
  }

  .pager {
    margin: 3rem 0 2rem;

    a {
      background: var(--color-pharlap);
      border-radius: 30px;
      color: var(--color-white);
      font-family: var(--font-avenir);
      font-size: 16px;
      font-weight: bold;
      padding: 10px 20px;
      text-align: center;
      transition: 0.5s;
      text-decoration: none;

      &:hover {
        background: var(--color-sushi);
      }
    }
  }
}

.node-type-resources {
  
  article {

    footer {
      display: none;
    }

    .field-name-body {

      .field-item {
        column-count: 2;
        column-gap: 30px;
      }

      p {
        margin-bottom: 25px;
      }
    }

    .field-name-field-image {
      margin-bottom: 30px;

      .field-items {
        display: flex;
        gap: 30px;
        justify-content: space-between;
        align-items: center;

        .field-item {
          line-height: 0;
        }
      }
    }

    .field-name-field-resource-type {

      a {
        background-color: var(--color-sushi);
        border-radius: 3px;
        color: var(--color-white);
        padding: 10px 15px;
      }
    }
  }
}

@include md {

  .view-resources {

    .resource-wrapper {
      flex-direction: column;

      .resource-left-content {

        .title {
          font-size: 20px;
          line-height: 26px;
        }
      }

      .resource-right-content {

        ul,
        li {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .node-type-resources {

    article {

      .field-name-field-image {

        .field-items {
          flex-direction: column;
        }
      }

      .field-name-body {

        .field-item {
          column-count: 1;
        }
      }
    }
  }
}
