// Import site utilities.
@import '../../global/utils/init';

.team-card {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;

  .team-card-left {
    flex-basis: calc(100% - 150px);
    text-align: center;
  }

  .team-card-right {
    align-items: baseline;
    display: flex;
    flex-basis: 150px;
  }

  img {
    margin: 0;
  }

  h4 {
    font-size: 18px;
    margin: 0 0 5px;
    text-transform: uppercase;
  }

  .serves {
    margin: 0 0 5px;
  }

  .field_specialties {

    ul {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        flex-basis: calc(50% - 5px);
        margin: 0;

        .vocabulary-specialties {
          border-radius: 10px;
          color: var(--color-white);
          font-size: 16px;
          line-height: 18px;
          padding: 5px 10px;
          text-align: center;
        }
      }
    }
  }
}

.team-card-wrapper {

  .field_about {
    line-height: 18px;
    text-align: left;

    &:empty {
      display: none;
    }
  }
}

.view-team-member {

  .view-content {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    .views-row {
      background: var(--color-pharlap);
      display: flex;
      flex-basis: calc(50% - 15px);
      padding: 15px;
    }
  }

  &.promoted {

    .team-card {

      .team-card-left {
        flex-basis: calc(100% - 250px);
      }

      .team-card-right {
        flex-basis: 250px;
      }
    }

    .view-content {

      .views-row {
        flex-basis: 65%;
        margin: 0 auto;
      }
    }
  }
}

@include lg {

  .team-card {

    .field_specialties {

      ul {
        flex-direction: column;

        li {
          width: 100%;
        }
      }
    }
  }
}

@include md {

  .view-team-member {

    .view-content {
      flex-direction: column;
    }

    &.promoted {

      .view-content {

        .views-row {
          flex-basis: 100%;
        }
      }
    }
  }

  .team-card {
    flex-direction: column;

    .field_specialties {

      ul {

        li {
          
          .vocabulary-specialties {
            font-size: 15px;
          }
        }
      }
    }
  }
}
