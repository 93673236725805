// Import site utilities.
@import '../../global/utils/init';

.l-footer {
  background: var(--color-millbrook);
  border: none;
  padding: 50px 0 0;

  .l-footer-inner {
    align-items: center;
    display: flex;
    flex-direction: column;

    .block {
      margin-bottom: 15px;
      text-align: center;
      width: 100%;
    }

    .block-system-header {

      .block-content {
        display: block;

        .header-logo-wrapper {
          height: auto;
          margin: 0;
        }
      }
    }

    .block-system-user-menu {

      .menu {
        display: flex;
        justify-content: center;

        a {
          color: var(--color-white);
          text-decoration: none;

          &:hover {
            color: var(--color-sushi);
          }
        }
      }
    }

    a {

      &:hover {
        color: var(--color-sushi);
      }
    }

    .block-block-copyright {

      p {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
